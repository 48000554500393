<header>
  <div class="d-flex align-items-center justify-content-between">
    <div class="logo">
      <ng-container>
        <a (click)="onClick($event)">
          <img src="assets\media\img\kyberneticka_bezpecnost-01.svg" alt="KyberBezpečnosť" width="360">
        </a>
      </ng-container>
    </div>
    <div>
      <button class="ml-2" mat-button [matMenuTriggerFor]="menu">
        <mat-icon>account_circle</mat-icon>
        {{ this.user.name }}
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before">
        <div>
          <button class="border-radius-0 t-d-n text-dark" (click)="logout()" mat-menu-item>
            <mat-icon class="color-red mat-18 mr-2">logout</mat-icon>
            Odhlásiť sa
          </button>
        </div>
      </mat-menu>
    </div>
  </div>
</header>
