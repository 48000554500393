import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TokenInterceptor} from './core/interceptor/token.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './core/interceptor/error.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DashboardComponent } from './views/page/dashboard/dashboard.component';
import {ThemeModule} from './views/theme/theme.module';
import {SharedModule} from './views/shared/shared.module';
import {MaterialModule} from './views/modules/material/material.module';
import { NonAuthZoneComponent } from './views/page/non-auth-zone/non-auth-zone.component';
import { SignpostComponent } from './views/page/signpost/signpost.component';
import { AuditorZoneComponent } from './views/page/auditor-zone/auditor-zone.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatNativeDateModule} from '@angular/material/core';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, NgxMatMomentModule} from '@angular-material-components/moment-adapter';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NonAuthZoneComponent,
    SignpostComponent,
    AuditorZoneComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeModule,
    SharedModule,
    MaterialModule,
    MatMomentDateModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
