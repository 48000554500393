import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarService} from '../../../core/services/snack-bar.service';

@Component({
  selector: 'app-snack-bar',
  template: ``,
})
export class SnackBarComponent implements OnInit {
  durationInSeconds = 5;

  constructor(
    private readonly snackBarService: SnackBarService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.snackBarService.appSnackBar.subscribe(message => {
      this.openSnackBar(message.toString());
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Zatvoriť', {
      duration: this.durationInSeconds * 1000,
    });
  }
}
