import {Component, Input, OnInit} from '@angular/core';
import {MENUITEMS} from '../../../core/data/menu-items';
import {MenuItem} from '../../../core/models/domain/menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {FirmService} from '../../../core/services/firm.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() menuItems: MenuItem[];

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private firmService: FirmService,
  ) { }

  ngOnInit(): void {
  }

}
