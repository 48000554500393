<div class="menu-wrap">
  <ul>
    <li *ngFor="let menuItem of menuItems; index as i">
      <a routerLinkActive="active" href="#" routerLink="/{{menuItem.routerLink}}">
        <mat-icon>{{menuItem.icon}}</mat-icon>
        {{menuItem.title}}
      </a>
    </li>
  </ul>
</div>
