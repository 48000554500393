import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';
import {MaterialModule} from '../modules/material/material.module';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { MenuComponent } from './menu/menu.component';



@NgModule({
  declarations: [HeaderComponent, SimpleHeaderComponent, MenuComponent],
    exports: [
        HeaderComponent,
        SimpleHeaderComponent,
        MenuComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MaterialModule,
    SharedModule,
    MatIconModule,
  ]
})
export class ThemeModule { }
