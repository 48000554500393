<div mat-dialog-title class="d-flex justify-content-between close-wrap">
  <h2 class="mr-3">
    <ng-container *ngIf="data.title; else defTitle">{{data.title}}</ng-container>
    <ng-template #defTitle>
      Ste si istý?
    </ng-template>
  </h2>
  <button mat-icon-button class="close-icon" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p class="text-center pt-2 pb-3"><strong>{{data.text}}</strong></p>
</div>
<div mat-dialog-actions class="col-12">
  <div class="button-wrap w-100 d-flex justify-content-between">
    <div>
      <button class="mr-3" mat-raised-button color="dark" [mat-dialog-close]="false">
        <ng-container *ngIf="data.title; else defButtonNo">{{data.button_no}}</ng-container>
        <ng-template #defButtonNo>
          Nie, nezmazať
        </ng-template>
      </button>
    </div>
    <div>
      <button color="primary" [mat-dialog-close]="true" mat-raised-button>
        <ng-container *ngIf="data.title; else defButtonYes">{{data.button_yes}}</ng-container>
        <ng-template #defButtonYes>
          Zmazať
        </ng-template>
      </button>
    </div>
  </div>
</div>
