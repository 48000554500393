import {Injectable} from '@angular/core';
import {Firm} from '../models/domain/firm';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirmService {
  firm: Firm;

  setFirm(firm: any): void {
    localStorage.setItem('firm', JSON.stringify(firm));
  }

  constructor(private http: HttpClient) {
  }

  getFirm(): Observable<Firm> {
    return this.http.get<Firm>(`${environment.apiUrl}/firm/activeFirm`);
  }

  getFirms(): Observable<Firm[]> {
    return this.http.get<Firm[]>(`${environment.apiUrl}/firm/all`);
  }

  getUserFirms(): Observable<Firm[]> {
    return this.http.get<Firm[]>(`${environment.apiUrl}/firm/getAllUserFirms`);
  }

  getContactPerson(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/firm/contactPerson`);
  }

  getContract(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/firm/contract`);
  }

  getFirmWithoutUsers(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/firm/allWithoutUsers`);
  }
}
