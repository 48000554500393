import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div *ngIf="isLoading" class="load-wrap">
      <div id="loader">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>`,
})
export class LoaderComponent implements OnInit {
  @Input() isLoading: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}

