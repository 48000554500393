import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {CustomFile} from '../../../core/models/domain/file';
import {StorageService} from '../../../core/services/storage.service';

@Component({
  selector: 'app-images-dialog',
  templateUrl: './images-dialog.component.html',
  styleUrls: ['./images-dialog.component.scss']
})
export class ImagesDialogComponent implements OnInit {
  formGroup: FormGroup;
  submitted = false;
  isProcessing = false;
  isLoading = false;
  isTotal = false;
  transfer = false;
  error = false;
  img: { size: number; name: string; type: string; content: string | ArrayBuffer };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImagesDialogComponent>,
    public snackBarService: SnackBarService,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    public dialog: MatDialog,
  ) {
    dialogRef.disableClose = true;
    dialogRef.backdropClick().subscribe(() => {
      this.closeDialolog();
    });
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      date: [new Date()],
      file: null,
    });
  }

  onFileDropped($event: any): void {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event, files): void {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: CustomFile[]): void {
    this.isLoading = true;
    for (const item of files) {
      this.getChangeFile(item);
    }
  }

  closeDialolog(): void {
    if (!this.isLoading && !this.transfer) {
      this.dialogRef.close();
    } else {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        maxWidth: '520px',
        autoFocus: false,
        data: {
          title: 'Pozor',
          text: 'Nahrávanie súborov nebolo ukončené. Vypnutím sa nenahrajú všetky súbory správne.',
          button_no: 'Chcem vypnúť',
          button_yes: 'Zavrieť',
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === false) {
          this.dialogRef.close();
        }
      });
    }
  }

  onSubmit(): void  {
    this.submitted = true;
    this.transfer = true;
    this.isLoading = true;
    this.storageService.upload(this.img).subscribe(r => {
      this.snackBarService.snack('Obrázok bol nahratý');
      const data = {
        img: this.img,
        hash: r.hash
      };
      this.dialogRef.close(data);
      this.isLoading = false;
    },
    error => {
      this.snackBarService.snack('Ľututjeme ale obrázok sa nepodarilo nahrať');
      this.error = true;
      this.submitted = false;
      this.transfer = false;
      this.isLoading = false;
    });
  }

  private getChangeFile(item: CustomFile): void {
    const file = item;
    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);
    reader.onload = () => {
      this.img = {
        name: file.name,
        type: file.type,
        size: file.size,
        content: reader.result
      };
      this.isLoading = false;
    };
  }

  remove($event: MouseEvent): void {
    this.img = null;
  }

}
