import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

import {AuthService} from '../services/auth.service';
import {SnackBarService} from '../services/snack-bar.service';
import {MatDialog} from '@angular/material/dialog';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(AuthService) private readonly authService: AuthService,
    @Inject(SnackBarService) private readonly snackBarService: SnackBarService,
    private router: Router,
    private dialogRef: MatDialog
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          this.router.navigate(['/auth/login']).then();
        }

        // this.snackBarService.snack(error.error.message || error.statusText);
        return throwError(error);
      })
    );
  }
}
