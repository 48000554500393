import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) {
  }

  sendLoginEmail(email: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/login-email`, email);
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  isAuth(): boolean {
    return localStorage.getItem('token') != null;
  }

  signOut(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  getToken(): string {
    return atob(localStorage.getItem('token'));
  }
}
