<form [formGroup]="formGroup">
  <input formControlName="date" type="hidden">

  <div mat-dialog-title class="d-flex justify-content-between align-items-center close-wrap">
    <h2 class="m-0">Pridať obrázok</h2>
    <button mat-icon-button class="close-icon" type="button" (click)="closeDialolog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="upload-file-wrap" mat-dialog-content>
    <div [ngClass]="{'d-none':  this.img}" class="dnd-storage-wrap">
      <div class="dnd-storage" (fileDropped)="onFileDropped($event)" appDnd>
        <input (change)="fileBrowseHandler($event, $event.target.files)" formControlName="file" id="fileDropRef"
            type="file" />
        <p>Presuňte alebo nahrajte nový obrázok</p>
      </div>
    </div>
    <div [ngClass]="{'d-none':  !this.img}">
      <div class="d-flex w-100 pt-3 pb-2">
        <img *ngIf="this.img && this.img.content" src="{{this.img.content}}" alt="img">
        <button mat-icon-button (click)="remove($event)" class="close-icon color-red" type="button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="error">
      <p class="color-red center">Súbor sa nepodarilo nahrať</p>
    </div>
  </div>
  <div mat-dialog-actions class="pt-3">
    <div class="button-wrap w-100 d-flex justify-content-between">
      <div>
        <button mat-raised-button class="mr-2" color="dark" (click)="closeDialolog()" type="button">Zrušiť</button>
      </div>
      <div>
        <button color="primary" [attr.data-qa]="'fileUpload'" (click)="onSubmit()" mat-raised-button [disabled]="submitted || isLoading">Nahrať obrázok</button>
      </div>
    </div>
  </div>
</form>

<app-loader [isLoading]="isLoading"></app-loader>
