import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {User} from '../models/domain/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User;

  storeNewUser = new Subject<User>();

  constructor(private http: HttpClient) {
  }

  loggedUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/loggedUser`);
  }

  setFirm(firmId: number): Observable<void> {
    return this.http.get<void>(`${environment.apiUrl}/user/setFirm/${firmId}`);
  }

  getRole(): string {
    return JSON.parse(localStorage.getItem('user'));
  }
  storeLogin(user: any): void {
    localStorage.setItem('user', JSON.stringify(user));
  }
}
