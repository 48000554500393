import { Component, OnInit } from '@angular/core';
import {AUDITORMENUITEMS, MENUITEMS} from '../../../core/data/menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {FirmService} from '../../../core/services/firm.service';
import {UserService} from '../../../core/services/user.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  template: `<div class="dashboard-wrap">
                <app-snack-bar></app-snack-bar>
                <div class="container">
                  <app-header></app-header>
                  <app-menu [menuItems]="menuItems"></app-menu>
                  <router-outlet></router-outlet>
                </div>
              </div>`,
})
export class DashboardComponent implements OnInit {
  menuItems = MENUITEMS;

  constructor(
    public route: ActivatedRoute,
    private firmService: FirmService,
    private userService: UserService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.firmService.firm = this.route.snapshot.data.firm;
    this.userService.user = this.route.snapshot.data.user;
    const title = this.firmService.firm.name + ' | Osobnyudaj.sk';
    this.titleService.setTitle(title);
    if (this.userService.user.role === 'auditor') {
      this.menuItems = AUDITORMENUITEMS;
    }
  }

}
