import { Component, OnInit } from '@angular/core';
import {AUDITORMENUITEMS, MENUITEMS} from '../../../core/data/menu-items';
import {ActivatedRoute, Router} from '@angular/router';
import {FirmService} from '../../../core/services/firm.service';
import {UserService} from '../../../core/services/user.service';
import localeSk from '@angular/common/locales/sk';
import {registerLocaleData} from '@angular/common';
registerLocaleData(localeSk, 'sk');

@Component({
  selector: 'app-auditor-zone',
  template: `<div class="auditor-zone">
                <app-snack-bar></app-snack-bar>
                <div class="container">
                  <app-header></app-header>
                  <app-menu [menuItems]="menuItems"></app-menu>
                  <router-outlet></router-outlet>
                </div>
              </div>`,
})
export class AuditorZoneComponent implements OnInit {
  menuItems = AUDITORMENUITEMS;

  constructor(
    public route: ActivatedRoute,
    private firmService: FirmService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.firmService.firm = this.route.snapshot.data.firm;
    this.userService.user = this.route.snapshot.data.user;
    const path = window.location.pathname.replace(/\//g, '');
  }
}

