import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./views/page/non-auth-zone/non-auth-zone.module')
      .then(m => m.NonAuthZoneModule),
  },
  {
    path: 'signpost',
    loadChildren: () => import('./views/page/signpost/signpost.module')
      .then(m => m.SignpostModule),
  },
  {
    path: 'auditor',
    loadChildren: () => import('./views/page/auditor-zone/auditor-zone.module')
      .then(m => m.AuditorZoneModule),
  },
  {
    path: '',
    loadChildren: () => import('./views/page/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
   })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
