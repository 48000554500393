import {MenuItem} from '../models/domain/menu-items';


export const MENUITEMS: MenuItem[] = [
  // {
  //   title: 'Audit KB',
  //   routerLink: 'questionnaire',
  //   icon: 'home',
  //   isActive: true
  // },
  {
    title: 'Analýzy IT',
    //icon: 'restore',
    icon: 'home',
    routerLink: 'audits',
    isActive: false,
  },
];

export const AUDITORMENUITEMS: MenuItem[] = [
  {
    title: 'Klienti',
    icon: 'home',
    routerLink: 'auditor',
    isActive: true
  },
];
