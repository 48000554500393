import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private http: HttpClient) { }

  upload(file): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/storage/store`, file);
  }
  getFile(hash: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/storage/${hash}`);
  }
}
