import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../core/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user;
  isLoading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.user = this.userService.user;
  }

  logout(): void {
    this.authService.signOut();
    this.router.navigate(['/auth/login']).then();
  }

  onClick($event: MouseEvent): void {
    if (this.user.role === 'auditor') {
      this.router.navigate(['/auditor']).then();
    } else {
      this.router.navigate(['/audits']).then();
    }
  }
}
