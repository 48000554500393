import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SnackBarComponent} from './snack-bar/snack-bar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ImagesDialogComponent } from './images-dialog/images-dialog.component';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../modules/material/material.module';
import {DndDirective} from '../../core/directive/dnd.directive';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {LoaderComponent} from './loader/loader.component';

@NgModule({
  declarations: [
    SnackBarComponent,
    NotFoundComponent,
    ImagesDialogComponent,
    ConfirmationDialogComponent,
    DndDirective,
    LoaderComponent,
  ],
  exports: [
    SnackBarComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MaterialModule,
  ]
})
export class SharedModule { }
